<template>
  <div>
    <div class="row">
      <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4', 'scenario-list']"> 
        <c-table
          ref="table"
          title="평가대상 목록"
          :columns="gridColumns"
          :gridHeight="setheight"
          :data="actionData.scenarioList"
          :merge="scenarioViewGroup==='L' ? grid.merge : []"
          :customTrClass="setTrClass"
          :filtering="false"
          :columnSetting="false"
          :plantCd="assessPlan.plantCd"
          :vendorCd="targetData.assessVendorId"
          :usePaging="false"
          :editable="editable&&!disabled"
          :isExcelDown="scenarioViewGroup==='L'"
          :isFullScreen="scenarioViewGroup==='L'"
          selection="multiple"
          rowKey="riskAssessmentTargetId"
          @innerBtnClicked="innerBtnClicked"
          @table-data-change="tableDataChange"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn 
                v-show="isCompared" 
                label="이전평가결과조회" 
                icon="compare_arrows" 
                @btnClicked="compareScenario" />
              <c-btn v-show="editable&&!disabled&&scenarioViewGroup==='L'" label="타위험성평가 결과추가" icon="save_alt" @btnClicked="addDiffScenario" />
              <c-btn v-show="editable&&!disabled&&scenarioViewGroup==='L'" label="추가" icon="add" @btnClicked="addScenario" />
              <c-btn 
                v-show="editable&&!disabled&&scenarioViewGroup==='L'" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="actionData.scenarioList"
                mappingType="PUT"
                label="저장" 
                icon="save"
                @beforeAction="saveScenario"
                @btnCallback="saveCallback" />
              <c-btn v-show="editable&&!disabled" label="삭제" icon="remove" @btnClicked="removeScenario" />
            </q-btn-group>
          </template>
          <template v-slot:suffixTitle>
            <q-btn-group outline class="scenario-view-group">
              <q-btn 
                :outline="scenarioViewGroup!=='L'"
                :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="view_list"
                @click="listView"
              >
                <q-tooltip>
                  목록형
                </q-tooltip>
              </q-btn>
              <q-btn 
                :outline="scenarioViewGroup!=='C'"
                :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="space_dashboard"
                @click="cardView"
              >
                <q-tooltip>
                  카드형
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='src'">
              <q-btn 
                round unelevated 
                size="6px"
                color="amber" 
                icon="search"
                @click="srcClick(props.row, props.pageIndex)" />
            </template>
            <template v-else-if="col.name==='sopName'">
              <c-text-column
                :editable="editable&&!disabled"
                :disabled="disabled"
                :col="col"
                :props="props"
                @datachange="datachange(props.row, col)"
              />
              <div v-if="editable&&!disabled&&Boolean(props.row.sopName)">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    icon="add"
                    color="green"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props)">
                    <q-tooltip>
                      평가실행업체 추가
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
            </template>
            <template v-if="col.name==='customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
              <q-chip
                v-for="(item, index) in $comm.ibmTagItems(props.row)"
                :key="index"
                :color="item.color"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click="linkClick(item, props.row)">
                {{item.title}}
                <c-assess-tooltip 
                  :ibmTooltip="item.ibmTooltip"
                  :ibmClassCd="item.ibmClassCd"
                />
              </q-chip>
            </template>
            <template v-else-if="col.name==='item'">
              <q-item class="card-scenario-list">
                <q-item-section>
                  <q-item-label class="scenario-card-title">{{
                    props.row['riskHazardClassCd'] ? props.row['editFlag'] == 'C' ? '작성중' : `${props.row['processName']} [${props.row['sopName']}] - ${props.row['riskHazardClassCd']}` 
                      : props.row['editFlag'] == 'C' ? '작성중' : `${props.row['processName']} [${props.row['sopName']}]`}}</q-item-label>
                </q-item-section>
                <q-item-section side style="padding-right: 0px;">
                  <q-btn 
                    v-if="props.row['editFlag'] !== 'C'"
                    unelevated  dense
                    color="primary"
                    label="▶"
                    @click.stop="getDetail(props.row.riskAssessmentTargetId)" />
                </q-item-section>
              </q-item>
            </template>
            <template v-else>
            </template>
            <div v-if="col.name==='customCol' && editable && Boolean(props.row.riskAssessmentTargetId) && props.row.editFlag !== 'C' && !disabled">
              <q-btn-group outline class="ColumInnerBtnGroup">
                <q-btn
                  icon="add"
                  color="orange-6"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnImprClicked(col, props, '1')">
                  <q-tooltip>
                    개선요청등록
                  </q-tooltip>
                </q-btn>
                <q-btn
                  icon="add"
                  color="red-6"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnImprClicked(col, props, '2')">
                  <q-tooltip>
                    즉시조치등록
                  </q-tooltip>
                </q-btn>
              </q-btn-group>
            </div>
            <template v-else-if="col.name==='picture'">
              <q-btn
                class="tableinnerBtn"
                flat
                align="center"
                color="blue-6"
                label=""
                @click.stop="openImprPicture(props)">
                <template v-slot:default >
                  <template v-if="props.row.editFlag!=='C'">
                    <q-btn 
                      unelevated round dense
                      size="10px"
                      color="primary" 
                      icon="wallpaper" />
                  </template>
                </template>
              </q-btn>
            </template>
            <template v-else-if="col.name==='riskPicture'">
              <q-btn 
                unelevated round dense
                size="10px"
                color="primary" 
                icon="wallpaper"
                @click.stop="openHazardPicture(props)" />
            </template>
          </template>
        </c-table>
      </div>
      <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
        <q-form ref="editForm">
          <div class="row">
            <div class="col-5">
              <c-card title="상세정보" class="cardClassDetailForm no-margin">
                <template slot="card-button">
                  <q-btn-group outline >
                    <c-btn 
                      v-show="editable && !disabled && selectScenario" 
                      :isSubmit="isSaveUnit"
                      :url="saveUnitUrl"
                      :param="scenario"
                      mappingType="PUT"
                      label="저장" 
                      icon="save"
                      @beforeAction="saveScenarioUnit"
                      @btnCallback="saveScenarioUnitCallback" />
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-12">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="280px"
                      :attachInfo="attachHazardInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-4">
                    <!-- 공종 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="공종"
                      name="scenario.processName"
                      v-model="scenario.processName">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 작업 -->
                    <c-text
                      required
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="작업"
                      name="sopName"
                      v-model="scenario.sopName">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 작업위치 -->
                    <q-btn 
                      round unelevated 
                      size="6px"
                      color="amber" 
                      icon="search"
                      @click="srcClick(scenario, -1)" />
                  </div>
                  <div class="col-8">
                    <!-- 유해위험요인 -->
                    <c-text
                      required
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="유해위험요인(위험한 상황과 사건)"
                      name="riskHazardClassCd"
                      v-model="scenario.riskHazardClassCd">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <!-- 관련법규 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="관련법규"
                      name="relationLaw"
                      v-model="scenario.relationLaw">
                    </c-text>
                  </div>
                  <div class="col-8">
                    <!-- 현재안전조치 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="현재안전조치"
                      name="currentSafetyMeasures"
                      v-model="scenario.currentSafetyMeasures">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-select
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isChip="true"
                      type="edit"
                      codeGroupCd="RAM_RISK_TYPE_CD"
                      itemText="codeName"
                      itemValue="code"
                      label="판단결과"
                      v-model="scenario.afterRisk">
                    </c-select>
                  </div>
                  <div class="col-6">
                    <!-- 평가일 -->
                    <c-datepicker
                      required
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="평가일"
                      name="assessDate"
                      v-model="scenario.assessDate"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가자 -->
                    <c-field
                      required
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :data="scenario"
                      :vendorCd="targetData.assessVendorId"
                      label="평가자"
                      name="assessUserId"
                      v-model="scenario.assessUserId"
                      @username="(val) => { scenario.assessUserName = val }" />
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-7">
              <c-card title="개선정보 [개선전 / 개선후]" class="cardClassDetailForm no-margin">
                <template slot="card-detail">
                  <div class="col-6">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="280px"
                      label="개선전"
                      :attachInfo="attachBeforeInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-6">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="280px"
                      label="개선후"
                      isMultiTask
                      :attachInfo="attachAfterInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                    <!-- 현재안전조치 -->
                  <!-- <div class="col-6">
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="현재안전조치"
                      name="riskOccurrenceCause"
                      v-model="scenario.riskOccurrenceCause">
                    </c-textarea>
                  </div> -->
                  <div class="col-8">
                    <c-radio
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :comboItems="improveFlagItems"
                      label="개선진행여부"
                      name="improveFlag"
                      v-model="scenario.improveFlag"
                      @datachange="improveFlagChange">
                    </c-radio>
                  </div>
                  <div class="col-12">
                    <!-- 개선 목록 -->
                    <c-table
                      title="개선 목록"
                      :editable="editable&&!disabled&&selectScenario"
                      :columns="gridImpr.columns"
                      :data="scenario.imprs"
                      :hideBottom="true"
                      :gridHeight="gridImpr.height"
                      @linkClick="(row) => linkClick(row, scenario)"
                    >
                      <template slot="table-button">
                        <q-btn-group outline>
                          <!-- 개선요청 -->
                          <c-btn 
                            v-if="editable&&!disabled&&selectScenario" 
                            label="개선"
                            color="orange-6"
                            icon="add" 
                            @btnClicked="openImprRequest('1')" />
                          <c-btn 
                            v-if="editable&&!disabled&&selectScenario" 
                            label="즉시" 
                            color="red-6"
                            icon="add" 
                            @btnClicked="openImprRequest('2')" />
                        </q-btn-group>
                      </template>
                    </c-table>
                  </div>
                    <!-- 추가 리스크관리 계획 -->
                  <!-- <div class="col-4">
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="추가 리스크관리 계획"
                      name="currentSafetyMeasures"
                      v-model="scenario.currentSafetyMeasures">
                    </c-textarea>
                  </div> -->
                </template>
              </c-card>
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-win-pop 
      ref="winPopup"  
      @onClose="val=>evtCloseWinPopup(val)"  
      @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'risk-assess-scenario',
  props: {
    actionData: {
      type: Object,
      default: () => ({
        plantCd: '',
        scenarioList: [], // 회의 목록
      }),
    },
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
      }),
    },
    targetData: {
      type: Object,
      default: () => ({
        riskAssessmentVendorId: '',
        riskAssessmentPlanId: '',
        assessVendorId: '',
        assessVendorName: '',
        processAssessStepCd: '',
        processAssessStepName: '',
        reCheckedCnt:0,
      }),
    },
    assessPlan: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        plantCd: '',
        assessTypeCd: '',
        assessmentName: '',
        assessmentStartDate: '',
        assessmentEndDate: '',
        assessmentYear: '',
        stepCd: '',
        assessmentManageUserId: '',
        assessmentManageVendorCd: '',
        ramMatrixId: null,
        reviewDate: '',
        approvalUserId: '',
        approvalDate: '',
        sopMapId: '',
        remark: '',
        reviewUserId: ''
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    planUpdateBtnData: {
      title: '계획',
      flag: false,
      research: '',
      planResearch: '',
    },
    updateBtnData: {
      title: '평가',
      flag: false,
      research: '',
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          // { index: 1, colName: 'groupMdmSopId' },
        ],
        columns: [],
        data: [],
        height: '600px'
      },
      scenario: {
        riskAssessmentTargetId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정
        beforeFrequency: 0,
        beforeStrength: 0,
        beforeRisk: 0,
        afterFrequency: 0,
        afterStrength: 0,
        afterRisk: null,
        groupMdmSopId: '', 
        mdmSopId: '', 
        sopName: '', 
        processAssessStepCd: '', 
        constructVendorCd: '', 
        workerCount: '', 
        currentSafetyMeasures: '', 
        assessVendorId: '', 
        assessVendorName: '', 
        riskOccurrenceCause: '', 
        riskOccurrenceResult: '', 
        riskHazardClassCd: null, 
        damageTypeCd: null,
        assessDate: '',  // 평가일
        assessUserId: '',  // 평가자 ID
        assessUserName: '',  // 평가자명
        vendorFlag: null, // 업체 작성 여부
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        imprs: [], // 개선목록
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      attachHazardInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_HAZARD',
        taskKey: '',
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'SCENARIO_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        task: []
      },
      improveFlagItems: [
        { code: 'X', codeName: '상관없음', },
        { code: 'Y', codeName: '개선진행', },
        { code: 'N', codeName: '유지', },
      ],
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:85px',
            sortable: true
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '개선담당업체',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:60px',
            sortable: true
          },
        ],
        height: '250px'
      },
      rowIndex: 0,
      isSaveUnit: false,
      isSave: false,
      listImprUrl: '',
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isComplete: false,
      scenarioViewGroup: 'L',
      saveUnitUrl: '',
      activeWinProps: {
        assessVendorId: '',
        riskAssessmentTargetId: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$refs.winPopup.closeWinPop()
    window.removeEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  computed: {
    isCompared() {
      return this.targetData.reCheckedCnt > 0;
    },
    disabled() {
      return this.param.stepCd === 'RAS000010' || this.targetData.processAssessStepCd === 'RS00000005'
            && !this.updateBtnData.flag
    },
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 235) + 'px' : '500px'
    },
    editable() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return this.$route.meta.editable && 
          (this.$store.getters.user.deptCd === this.targetData.assessVendorId
           || this.assessPlan.approvalUserId === this.$store.getters.user.userId
           || this.assessPlan.reviewUserId === this.$store.getters.user.userId
           || this.assessPlan.regUserId === this.$store.getters.user.userId)
      } else {
        return this.$route.meta.editable && 
          (this.$store.getters.user.selfVendorCd === this.targetData.assessVendorId
           || this.assessPlan.approvalUserId === this.$store.getters.user.userId
           || this.assessPlan.reviewUserId === this.$store.getters.user.userId
           || this.assessPlan.regUserId === this.$store.getters.user.userId)
      }
      // return this.assessPlan.approvalUserId === this.$store.getters.user.userId
      //   || this.assessPlan.reviewUserId === this.$store.getters.user.userId
      //   || this.assessPlan.regUserId === this.$store.getters.user.userId
    },
    selectScenario() {
      return Boolean(this.scenario.riskAssessmentTargetId)
    },
    afterRiskEditable() {
      return this.scenario.improveFlag !== 'N'
    },
    gridColumns() {
      let cols = [];
      if (this.scenarioViewGroup === 'L') {
        cols = [
          {
            required: true,
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'blue', tooltip: '동일한 공종 추가' }
            ]
          },
          {
            required: true,
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:200px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'src',
            field: 'src',
            label: '작업<br/>위치',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            required: true,
            name: 'riskHazardClassCd',
            field: 'riskHazardClassCd',
            label: '유해·위험요인<br/>(위험한 상황과 사건)',
            align: 'left',
            style: 'width:250px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'riskPicture',
            field: 'riskPicture',
            label: '사진',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false,
          },
          
          {
            name: 'relationLaw',
            field: 'relationLaw',
            label: '관련법규',
            align: 'left',
            style: 'width:130px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
            type: 'textarea'
          },
          {
            required: true,
            setHeader: true,
            name: 'afterRisk',
            field: 'afterRisk',
            label: '판단결과',
            align: 'center',
            style: 'width:90px',
            type: 'select',
            codeGroupCd: 'RAM_RISK_TYPE_CD',
            isChip: true,
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선<br/>전/후<br/>사진',
            align: 'center',
            type: 'custom',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:170px',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'improveFlag',
            field: 'improveFlag',
            label: '개선<br/>진행<br/>여부',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            comboItems: this.improveFlagItems
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:160px',
            type: 'custom',
            sortable: false
          },
          {
            setHeader: true,
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: false,
            required: true,
          },
          {
            setHeader: true,
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            type: 'user',
            userId: 'assessUserId',
            style: 'width:120px',
            sortable: false,
            required: true,
          },
        ]
      } else {
        cols = [
          {
            name: 'item',
            field: 'item',
            label: '평가내용',
            align: 'left',
            sortable: false,
            type: 'custom'
          },
        ]
      }
      return cols;
    },
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.saas.scenario.list.url
      this.listImprUrl = selectConfig.saas.scenario.impr.url
      this.detailUrl = selectConfig.saas.scenario.get.url
      this.saveUrl = transactionConfig.saas.scenario.save.url
      this.deleteUrl = transactionConfig.saas.scenario.delete.url
      this.saveUnitUrl = transactionConfig.saas.scenario.saveUnit.url
      
      if(this.$store.getters.user.innerFlag === 'N') {
        this.vendorCd = this.$store.getters.user.deptCd
        this.vendorName = this.$store.getters.user.deptName
      } 
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        assessVendorId: this.targetData.assessVendorId,
        riskAssessmentPlanId: this.targetData.riskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.actionData.scenarioList = _result.data;
      },);
    },
    getDetail(riskAssessmentTargetId) {
      this.$http.url = this.$format(this.detailUrl, riskAssessmentTargetId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);
        this.$set(this.attachHazardInfo, 'taskKey', this.scenario.riskAssessmentTargetId)
        this.$set(this.attachBeforeInfo, 'taskKey', this.scenario.riskAssessmentTargetId)
        // this.$set(this.attachAfterInfo, 'taskKey', this.scenario.riskAssessmentTargetId)
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.scenario.imprs, impr => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: impr.sopImprovementId,
          }
        }))
      },);
    },
    getImprs() {
      if (this.scenario.riskAssessmentTargetId) {
        this.$http.url = this.listImprUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          riskAssessmentTargetId: this.scenario.riskAssessmentTargetId,
        }
        this.$http.request((_result) => {
          console.log(_result.data)
          this.$set(this.scenario, 'imprs', _result.data)
        },);
      }
    },
    saveScenario() {
      if (this.$comm.validTable(this.gridColumns, this.actionData.scenarioList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {       
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // this.planUpdateBtnData.planResearch = uid();
      this.getList();
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              // this.planUpdateBtnData.planResearch = uid();

              this.$_.forEach(selectData, item => {
                this.actionData.scenarioList = this.$_.reject(this.actionData.scenarioList, item);
                this.$refs['table'].$refs['compo-table'].clearSelection();
              });
              // this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addScenario() {
      this.popupOptions.title = '단위공종 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.actionData.plantCd,
        riskAssessmentPlanId: this.param.riskAssessmentPlanId
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeProcessPopup;
    },
    closeProcessPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if(this.$_.findIndex(this.actionData.scenarioList, { processCd: _item.processCd }) === -1) {
            this.actionData.scenarioList.push({
              riskAssessmentTargetId: uid(),
              riskAssessmentPlanId: this.param.riskAssessmentPlanId,
              processCd: _item.processCd,
              processName: _item.processName,
              ramMatrixId: this.assessPlan.ramMatrixId,
              beforeFrequency: 0,
              beforeStrength: 0,
              beforeRisk: 0,
              afterFrequency: 0,
              afterStrength: 0,
              afterRisk: 0,
              groupMdmSopId: _item.processCd + '/' + uid(),
              mdmSopId: '',
              sopName: '',
              processAssessStepCd: '',
              constructVendorCd: '',
              workerCount: null,
              currentSafetyMeasures: '',
              assessVendorId: this.targetData.assessVendorId,
              assessVendorName: '',
              riskOccurrenceCause: '',
              riskOccurrenceResult: null,
              riskHazardClassCd: null,
              damageTypeCd: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
              assessDate: this.$comm.getToday(),
              assessUserId: this.$store.getters.user.userId,
              assessUserName: this.$store.getters.user.userName,
            })
          }
        })
      }
    },
    addDiffScenario() {
      this.popupOptions.title = '타 공사현장 유해위험요인 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.assessPlan.plantCd,
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
      };
      this.popupOptions.target = () => import(`${'./riskAssessScenarioPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '95%';
      this.popupOptions.closeCallback = this.closeDiffScenarioPopup;
    },
    closeDiffScenarioPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.$_.forEach(_item.maps, __item => {
            __item.riskAssessMapId = uid(),
            __item.riskAssessmentTargetId = process.riskAssessmentTargetId
            __item.editFlag = 'C'
          })
          this.actionData.scenarioList.push({
            riskAssessmentTargetId: uid(),
            riskAssessmentPlanId: this.param.riskAssessmentPlanId,
            processCd: _item.processCd,
            processName: _item.processName,
            ramMatrixId: this.assessPlan.ramMatrixId,
            beforeFrequency: _item.beforeFrequency,  
            beforeStrength: _item.beforeStrength,  
            beforeRisk: _item.beforeRisk,  
            afterFrequency: _item.afterFrequency,  
            afterStrength: _item.afterStrength,  
            afterRisk: _item.afterRisk, 
            groupMdmSopId: _item.processCd + '/' + _item.mdmSopId,
            mdmSopId: _item.mdmSopId,
            sopName: _item.sopName,
            processAssessStepCd: '',
            constructVendorCd: _item.constructVendorCd,
            workerCount: _item.workerCount,
            currentSafetyMeasures: _item.currentSafetyMeasures,
            assessVendorId: this.targetData.assessVendorId,
            assessVendorName: '',
            riskOccurrenceCause: _item.riskOccurrenceCause,
            riskOccurrenceResult: _item.riskOccurrenceResult,
            riskHazardClassCd: _item.riskHazardClassCd,
            damageTypeCd: _item.damageTypeCd,
            maps: this.$_.cloneDeep(_item.maps),
            deleteMaps: [],
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C',
            assessDate: this.$comm.getToday(),
            assessUserId: this.$store.getters.user.userId,
            assessUserName: this.$store.getters.user.userName,
          })
        })
      }
    },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      this.isRowAdd = true;
      if (col.name === 'processName') {
        this.addProcess(props)
      } else if (col.name === 'sopName') {
        this.addSop(props)
      }
    },

    innerBtnImprClicked(col, props, btn) {
      if(!props.row.riskHazardClassCd) props.row.riskHazardClassCd = ''; 
      this.requestImprRow = props.row
      let requestContents = '[' + this.targetData.assessVendorName +'] ' + 
        props.row.processName + ' / ' + props.row.sopName + '/' + props.row.riskHazardClassCd 
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: props.row.riskAssessmentTargetId,
          ibmTaskTypeCd: 'ITT0000005',
          plantCd: this.assessPlan.plantCd,
          sopMapId: this.assessPlan.sopMapId,
          approvalUserId: this.assessPlan.approvalUserId,
          ibmTitle: props.row.processName + '/' + props.row.sopName + '/'
              + props.row.riskHazardClassCd,
          row: props.row
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: props.row.riskAssessmentTargetId,
          ibmTaskTypeCd: 'ITT0000005',
          plantCd: this.assessPlan.plantCd,
          sopMapId: this.assessPlan.sopMapId,
          approvalUserId: this.assessPlan.approvalUserId,
          ibmTitle: props.row.processName + '/' + props.row.sopName + '/'
              + props.row.riskHazardClassCd,
          row: props.row
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
            this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
              this.requestImprRow.ibmClassCds + ',' + item.ibmClassCd : item.ibmClassCd
            this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
              this.requestImprRow.ibmTooltip + ',' + item.ibmTooltip : item.ibmTooltip
          }
        })
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getList();
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getImprs();
        this.getDetail(this.scenario.riskAssessmentTargetId);
      }
    },
    addProcess(props) {
      let groupMdmSopId = props.row.processCd + '/' + uid();
      let process = { 
        riskAssessmentTargetId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        groupMdmSopId: groupMdmSopId,
        processCd: props.row.processCd,
        processName: props.row.processName,
        ramMatrixId: this.assessPlan.ramMatrixId,
        mdmSopId: null ,
        beforeFrequency: 0,
        beforeStrength: 0,
        beforeRisk: 0,
        afterFrequency: 0,
        afterStrength: 0,
        afterRisk: 0,
        sopName: '',
        saiContructFacilityId: '',
        maps: this.$_.cloneDeep(props.row.maps),
        deleteMaps: [],
        facilityName: '',
        processAssessStepCd: '',
        constructVendorCd: '',
        workerCount: 0,
        assessVendorId: this.targetData.assessVendorId,
        assessVendorName: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
        assessDate: this.$comm.getToday(),
        assessUserId: this.$store.getters.user.userId,
        assessUserName: this.$store.getters.user.userName,
      }
      this.$_.forEach(process.maps, _item => {
        _item.riskAssessMapId = uid(),
        _item.riskAssessmentTargetId = process.riskAssessmentTargetId
        _item.editFlag = 'C'
      })

      this.actionData.scenarioList.splice(this.rowIndex, 0, process)
    },
    addSop(props) {
      let sop = { 
        riskAssessmentTargetId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        groupMdmSopId: props.row.groupMdmSopId,
        processCd: props.row.processCd,
        processName: props.row.processName,
        ramMatrixId: this.assessPlan.ramMatrixId,
        beforeFrequency: 0,
        beforeStrength: 0,
        beforeRisk: 0,
        afterFrequency: 0,
        afterStrength: 0,
        afterRisk: 0,
        mdmSopId: props.row.mdmSopId,
        sopName: props.row.sopName,
        maps: this.$_.cloneDeep(props.row.maps),
        deleteMaps: [],
        saiContructFacilityId: '',
        facilityName: '',
        processAssessStepCd: '',
        constructVendorCd: '',
        workerCount: 0,
        assessVendorId: this.targetData.assessVendorId,
        assessVendorName: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
        assessDate: this.$comm.getToday(),
        assessUserId: this.$store.getters.user.userId,
        assessUserName: this.$store.getters.user.userName,
      }
      this.$_.forEach(sop.maps, _item => {
        _item.riskAssessMapId = uid(),
        _item.riskAssessmentTargetId = sop.riskAssessmentTargetId
        _item.editFlag = 'C'
      })
      this.actionData.scenarioList.splice(this.rowIndex, 0, sop)
    },
    datachange(row) {
      if (row.editFlag !== 'C') {
        row.editFlag = 'U'
        row.chgUserId = this.$store.getters.user.userId
      }
    },
    srcClick(row) {
      this.row = row;

      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: row.riskAssessmentTargetId,
        maps: row.maps ? row.maps : [],
        deleteMaps: row.deleteMaps ? row.deleteMaps : [],
        sopMapId: this.assessPlan.sopMapId,
        stepCd: this.param.stepCd,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.type === 'APPLY') {
        this.row.maps = data.maps;
        this.row.deleteMaps = data.deleteMaps;
        
        if (this.row.editFlag !== 'C') {
          this.row.editFlag = 'U'
          this.row.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    tableDataChange(props, col) {
      if (col.name === 'beforeFrequency') {
        this.$set(props.row, 'beforeRisk', props.row.beforeFrequency * props.row.beforeStrength)
      } else if (col.name === 'beforeStrength') {
        this.$set(props.row, 'beforeRisk', props.row.beforeFrequency * props.row.beforeStrength)
      } else if (col.name === 'afterFrequency') {
        this.$set(props.row, 'afterRisk', props.row.afterFrequency * props.row.afterStrength)
      } else if (col.name === 'afterStrength') {
        this.$set(props.row, 'afterRisk', props.row.afterFrequency * props.row.afterStrength)
      }
    },
    riskDataChange(scenario, name) {
      if (name === 'beforeFrequency') {
        this.$set(scenario, 'beforeRisk', scenario.beforeFrequency * scenario.beforeStrength)
      } else if (name === 'beforeStrength') {
        this.$set(scenario, 'beforeRisk', scenario.beforeFrequency * scenario.beforeStrength)
      } else if (name === 'afterFrequency') {
        this.$set(scenario, 'afterRisk', scenario.afterFrequency * scenario.afterStrength)
      } else if (name === 'afterStrength') {
        this.$set(scenario, 'afterRisk', scenario.afterFrequency * scenario.afterStrength)
      }
    },
    listView() {
      this.scenarioViewGroup = 'L'
      this.getList();
    },
    cardView() {
      this.scenarioViewGroup = 'C'
      this.getList();
    },
    saveScenarioUnit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail(this.scenario.riskAssessmentTargetId)
    },
    improveFlagChange() {
      if (this.scenario.improveFlag === 'N') {
        // 유지하는 경우 개선 전 위험성의 수준를 복사 및 계획에는 현상유지 setting
        this.$set(this.scenario, 'currentSafetyMeasures', '현상 유지')
        this.$set(this.scenario, 'afterFrequency', this.scenario.beforeFrequency)
        this.$set(this.scenario, 'afterStrength', this.scenario.beforeStrength)
        this.$set(this.scenario, 'afterRisk', this.scenario.beforeRisk)
      }
    },
    openImprRequest(btn) {
      let requestContents = '[' + this.targetData.assessVendorName +'] ' + 
      this.scenario.processName + ' / ' + this.scenario.sopName + '/' + this.scenario.riskHazardClassCd 
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: this.scenario.riskAssessmentTargetId,
          ibmTaskTypeCd: 'ITT0000005',
          plantCd: this.assessPlan.plantCd,
          sopMapId: this.assessPlan.sopMapId,
          approvalUserId: this.assessPlan.approvalUserId,
          ibmTitle: this.scenario.processName + '/' + this.scenario.sopName + '/'
              + this.scenario.riskHazardClassCd,
          row: this.scenario
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeCardImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: this.scenario.riskAssessmentTargetId,
          ibmTaskTypeCd: 'ITT0000005',
          plantCd: this.assessPlan.plantCd,
          sopMapId: this.assessPlan.sopMapId,
          approvalUserId: this.assessPlan.approvalUserId,
          ibmTitle: this.scenario.processName + '/' + this.scenario.sopName + '/'
              + this.scenario.riskHazardClassCd,
          row: this.scenario
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeCardImprPopup;
      }
    },
    closeCardImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprs();
    },
    openHazardPicture(props) {
      this.popupOptions.title = '위험요인 사진';
      this.popupOptions.param = {
        riskAssessmentTargetId: props.row.riskAssessmentTargetId,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'./riskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진';
      this.popupOptions.param = {
        riskAssessmentTargetId: props.row.riskAssessmentTargetId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'./actionScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    compareScenario() {
      let uri = `/saas/action/riskAssessCompareScenario?riskAssessmentPlanId=${this.param.riskAssessmentPlanId}&assessVendorId=${this.targetData.assessVendorId}`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if (recvObj) {
        this.$set(this.activeWinProps, 'assessVendorId', recvObj.assessVendorId)
        this.$set(this.activeWinProps, 'riskAssessmentTargetId', recvObj.riskAssessmentTargetId)
      }
    },
    setTrClass(props) {
      return props.row.assessVendorId === this.activeWinProps.assessVendorId 
        && props.row.compareId === this.activeWinProps.riskAssessmentTargetId ? 'bg-purple-2' : '';
    },
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important

.scenario-view-group 
  button
    margin: 0 !important

.scenario-card-title
  margin-left: -5px
  font-size: 0.8rem

.scenario-card-side
  text-align: center
  align-self: center
  // flex-basis: 50%

.card-risk-item
  padding-top: 0px
  padding-bottom: 0px
  min-height: auto
.card-risk-info
  width: auto
  min-width: 0
  max-width: 100%
  flex: 10000 1 0%
  display: flex
  border-radius: 10px
  font-size: 1rem
  height: 30px
.before-risk
  background: #3A98B9
  margin-right: 5px
.after-risk
  background: #3A98B9
  margin-left: 5px
.risk-number
  font-size: 1.3rem

.detail-risk
  font-size: 0.8rem
  font-weight: 600
.riskAssessmentTargetId-num
  font-size: 1rem
  font-weight: 700
  color: #e63946

.card-scenario-list
  padding: 0px 16px !important
  min-height: 28px
</style>
